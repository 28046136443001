@tailwind base;

@tailwind components;

@tailwind utilities;

@layer utilities {
   .absolute-center {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
   }
   .no-anchor {
      overflow-anchor: none;
   }
}

.lg\:flex-nowrap {
   @screen lg {
      flex-wrap: nowrap !important;
   }
}

.font-harir {
   .text-sm {
      @apply text-lg;
   }
   .text-base {
      @apply text-lg;
   }
   .text-xs {
      @apply text-base;
   }
   .text-lg {
      @apply text-xl;
   }
   .harir-adjust {
      @apply pt-1.5;
   }

   .font-circular,
   .font-arb {
      @apply !font-harir;
   }
   input {
      @apply pt-3 !text-lg !leading-none;
   }
   .harir-adjust-input {
      @apply pt-4;
   }
}

/**
  Sentry Overrise 
**/
.sentry-error-embed .powered-by {
   display: none;
}
