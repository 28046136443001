@font-face {
   font-family: 'Circular';
   src: url('/fonts/circular/circular-light.ttf');
   font-weight: 300;
   font-display: swap;
}
@font-face {
   font-family: 'Circular';
   src: url('/fonts/circular/circular-normal.otf');
   font-weight: 400;
   font-display: swap;
}
@font-face {
   font-family: 'Circular';
   src: url('/fonts/circular/circular-normal.otf');
   font-weight: normal;
   font-display: swap;
}

@font-face {
   font-family: 'Circular';
   src: url('/fonts/circular/circular-bold.otf');
   font-weight: 700;
   font-display: swap;
}
@font-face {
   font-family: 'Circular';
   src: url('/fonts/circular/circular-black.otf');
   font-weight: 900;
   font-display: swap;
}

@font-face {
   font-family: 'Nothing You Could Do';
   src: url('/fonts/NothingYouCouldDo-Regular.ttf');
   font-weight: 400 normal;
   font-display: swap;
}

/* Arabic fonts */
@font-face {
   font-family: 'BR';
   src: url('/fonts/arb/arb-bold.ttf');
   font-weight: bold 700;
   font-display: swap;
}

@font-face {
   font-family: 'BR';
   src: url('/fonts/arb/arb-normal.ttf');
   font-weight: 300 400 normal;
   font-display: swap;
}

@font-face {
   font-family: 'Displace';
   src: url('/fonts/displace.ttf');
   font-weight: bold 700;
   font-display: swap;
}

@font-face {
   font-family: 'Harir';
   src: url('/fonts/harir/bold.otf');
   font-weight: bold 700;
   font-display: swap;
}

@font-face {
   font-family: 'Harir';
   src: url('/fonts/harir/normal.otf');
   font-weight: 300 400 normal;
   font-display: swap;
}
