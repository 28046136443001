.wrapper {
   @apply text-white text-lg bg-primary-color rounded-t-md fixed z-70 flex items-center justify-between px-3 tablet:w-64 tablet:rounded-md;
   bottom: 52px;
   left: 0;
   right: 0;
   @screen tablet {
      bottom: 15px;
      [dir='ltr'] & {
         left: 15px;
      }
      [dir='rtl'] & {
         right: 15px;
      }
   }
}
