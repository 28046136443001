.header {
   @apply text-2xs;
   @media (min-width: 1300px) {
      font-size: 0.75rem;
   }
}
.nav-link {
   @apply relative;
   a {
      @apply flex h-full items-center font-normal text-sm leading-none xl:text-sm;
      white-space: nowrap;
      svg {
         /* @apply transform transition-all duration-150 origin-bottom-right; */
      }
      g {
         /* @apply transition-all duration-200;*/
      }
   }
   &__trans:hover {
      background-color: #f8f9fa50 !important;
      border-radius: 14px;
   }
}

.active {
   @apply opacity-100;
}
@screen tablet {
   .active {
      opacity: 1;
   }
}
.active a {
   @apply text-gray-700 font-bold;
   svg {
      @apply xl:scale-110;
   }
}

.searchbar {
   border-radius: 14px;
   @apply bg-gray-100;
   &_sticked {
      @apply text-black font-light;
      &::placeholder {
         @apply text-black text-opacity-80 font-light;
      }
   }
}
.logout {
   border-radius: 13px;
}
.sticked_logout {
   background-color: #f8f9fa50;
}

.icon {
   @apply rounded-3xl shadow-md p-2;
   &:hover {
      @apply bg-gray-50;
   }
   &:focus {
      @apply bg-gray-50 outline-none;
   }
}

.bar-link {
   @apply whitespace-nowrap;
   @apply text-black py-1 px-4 hover:bg-white hover:bg-opacity-25 flex rounded-full ltr:mr-2 rtl:mr-2 items-center relative;
}

.dropdown {
   width: 275px;
}

.guest-dropdown {
   @apply w-[150px] mt-1;
}

.dropdown-menu {
   &:focus {
      background-color: #f8f9fa50;
   }
   &:hover {
      background-color: #f8f9fa50;
   }
}
.border {
   @apply border-l-2 border-r-2 border-dashed opacity-90 !important;
}
