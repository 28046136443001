.input {
   @apply rounded-default w-full focus:outline-none focus:ring-gray-400 focus:border-gray-400;
   @apply ltr:pl-2 px-8 rtl:pr-2 rtl:pl-8;

   &:disabled {
      @apply cursor-not-allowed;
   }
   &:not(:disabled) {
      @apply hover:border-gray-400;
   }

   &::focus {
      box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
   }
   &::-webkit-calendar-picker-indicator {
      display: none;
      -webkit-appearance: none;
   }
   [dir='ltr'] & {
      background-position: right calc(0.375em + 0.1875rem) center;
   }
   [dir='rtl'] & {
      background-position: left calc(0.375em + 0.1875rem) center;
   }
   @apply bg-no-repeat;
   background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);

   &:not(textarea) {
      height: 40px;
   }
   &.appended {
      @apply ltr:rounded-r-none rtl:rounded-l-none ltr:border-r-0 rtl:border-l-0;
   }
   &.prepended {
      @apply ltr:rounded-l-none ltr:border-l-0 rtl:rounded-r-none rtl:border-r-0;
   }
}

.error {
   @apply border-primary-error;
   box-shadow: 0px 0px 4px 0px rgba(255, 39, 39, 0.25);
   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");

   &:focus {
      box-shadow: 0px 0px 4px 0px rgba(255, 39, 39, 0.7);
   }
}
.valid {
   @apply border-primary-success;
   box-shadow: 0px 0px 4px 0px rgba(54, 181, 105, 0.25);
   background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");

   &:focus {
      box-shadow: 0px 0px 4px 0px rgba(54, 181, 105, 0.7);
   }
}

.append {
   min-width: 0.5rem;
}

.prepend {
   min-width: 0.5rem;
}
