/* NAVBAR ICONS ANIMATIONS */
#nav-tickets {
   @apply -mt-1;
}
#top-card {
   transform: translateY(3px) translateX(7px) rotate(13deg);
}

#exp-icon {
   @apply origin-center;
}

.active-icon {
   #top-card {
      transform: translateY(0) translateX(0) rotate(0);
   }

   #exp-icon {
      @apply rotate-180;
   }
}

#spoon,
#fork,
#attr-left {
   @apply duration-500;
   transform-box: fill-box;
}

#hy-tickets {
   @apply -mt-2;
}
#hy-top-card {
   transform: translateY(7px) translateX(9px) rotate(13deg);
}

#ping-ball {
   transform: translateY(-5px) translateX(3px);
}
#hand {
   @apply origin-bottom;
   transform: rotate(15deg);
}

.navigator {
   g,
   path,
   svg {
      @apply transition-all duration-200 ease-in-out;
   }
}

#spoon {
   @apply origin-bottom-right;
}
#fork {
   @apply origin-bottom-left;
}
#umbrella {
   @apply origin-center duration-500;
}
#beach-sun {
   @apply duration-500;
}
.navigator:hover {
   #hy-top-card {
      transform: translateY(0) translateX(0) rotate(0);
   }
   #screen {
      fill: white;
   }
   #online {
      @apply origin-bottom-right;
      animation: mobileMotion 1s infinite alternate;
   }
   #sun {
      animation: sunMotion 1s infinite alternate;
   }
   #island {
      transform: translateX(-8px);
   }

   #umbrella {
      transform: translateY(2px) rotate(-48deg);
   }
   #beach-sun {
      transform: translateX(8px);
   }
   #spoon {
      transform: rotate(-35deg);
   }
   #fork {
      transform: rotate(35deg);
   }
   #attr-left {
      @apply origin-bottom;
      animation: attractionMotion 500ms infinite alternate;
   }
   #hand {
      animation: handMotion 600ms infinite alternate;
   }
   #ping-ball {
      animation: ballMotion 600ms infinite alternate;
   }
   #heart {
      transform: scale(1.2);
   }
}

.feedback {
   > button svg {
      @apply transition duration-200 transform;
   }
   > button:hover svg {
      @apply scale-110;
   }
   > button:focus svg {
      @apply scale-110;
   }
}

/* .feedback {
   #heart-left,#heart-right{
      @apply transition-all duration-150 origin-center;
   }
   > div:hover {
      #heart-left,#heart-right {
         @apply transform scale-125;
      }
   }
} */

@keyframes mobileMotion {
   0% {
      transform: rotate(0);
   }
   100% {
      transform: rotate(15deg);
   }
}
@keyframes sunMotion {
   0% {
      transform: translateX(0);
   }
   100% {
      transform: translateX(-17px);
   }
}

@keyframes attractionMotion {
   0% {
      transform: translateY(0);
   }
   100% {
      transform: translateY(-8px);
   }
}
@keyframes handMotion {
   0% {
      transform: rotate(15deg);
   }
   100% {
      transform: rotate(0);
   }
}
@keyframes ballMotion {
   0% {
      transform: translateY(-5px) translateX(3px);
   }
   100% {
      transform: translateY(0) translateX(0);
   }
}
